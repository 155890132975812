import React from 'react'

export default props => {
    const [classes, setClasses] = React.useState([
        `scale-105 z-50 opacity-100 translate-y-0`,
        `scale-90 z-40 opacity-75 translate-y-4`,
        `scale-75 z-30 opacity-50 translate-y-8`,
    ])
    const items = ['First', 'Second', 'Third']
    React.useEffect(() => {
        const timer = setInterval(() => {
            let arr = [...classes]
            const last = arr.pop()
            let newArr = [last, ...arr]
            console.log('New arr', newArr)

            //
            setClasses(newArr)
        }, 2000)
        return () => {
            console.log('Finished')
            clearInterval(timer)
        }
    }, [classes])

    const getClass = idx => {
        return classes[idx]
    }

    return (
        <div className='mx-auto max-w-2xl mt-8 sm:mt-24 px-4 flex flex-col items-center'>
            <h1 className='sm:text-center sm:text-2xl font-semibold'>
                Test Page, you shouldn't be here
            </h1>
            <div className='my-32 w-128 flex flex-col items-center'>
                <ul className='relative flex items-center justify-center'>
                    {items.map((item, i) => (
                        <li
                            key={i}
                            className={`${getClass(
                                i,
                            )} absolute pin-0 bg-white shadow-xl w-72 h-40 rounded origin-bottom transform transition duration-700`}
                        >
                            {props.children}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export const query = graphql`
    query {
        file(name: { eq: "analytics" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
